// extracted by mini-css-extract-plugin
export var main = "project-detail-module--main--3NVtL";
export var crossBox = "project-detail-module--crossBox--qeZZl";
export var content = "project-detail-module--content--s1qhk";
export var imgColumn = "project-detail-module--imgColumn--2fZOs";
export var album = "project-detail-module--album--2XJUO";
export var right = "project-detail-module--right--G9WCG";
export var inner = "project-detail-module--inner--3T9dL";
export var title = "project-detail-module--title--2W6aP";
export var skew = "project-detail-module--skew--7O7dt";
export var next = "project-detail-module--next--2XRZ1";