import React from 'react'
import { Helmet } from 'react-helmet';
import { useQueryParam, NumberParam } from "use-query-params";

import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { useIntl } from "gatsby-plugin-intl"
import { navigate } from '@reach/router';
import { Album } from 'src/components/Album'

import { projects } from 'src/projectData.store'
import MoreIcon from '../icons/more.svg'
import CrossIcon from '../icons/close-project.svg'
//@ts-ignore
import * as Styles from './project-detail.module.scss'
import YearBox from 'src/components/YearBox';



const projectDetail = () => {
    const intl = useIntl()
    const breakpoints = useBreakpoint()
    const [projectId, setProjectId] = useQueryParam("projectId", NumberParam)
    return (
        <>
            <Helmet>
                <title>{intl.formatMessage({ id: 'INKA Limited' })}</title>
            </Helmet>
            <div lang={intl.locale} className={Styles.main}>

                <div className={Styles.content}>
                    {
                        breakpoints.sm ? <Album
                            images={
                                projects.find(item => item.projectId === projectId)?.imsrc ?? []
                            }
                            className={Styles.album}
                        /> : <div className={Styles.imgColumn}>
                                {/* <div className={Styles.gap} /> */}
                                {
                                    projects.find(item => item.projectId === projectId)?.imsrc.map(item => <img src={item} />)
                                }
                            </div>
                    }

                    <div className={Styles.right}>
                        <div className={Styles.inner}>
                            <MoreIcon />
                            <div >
                                <div className={Styles.title}>
                                    {intl.formatMessage({ id: projects.find(item => item.projectId === projectId)?.nameId ?? "default" })}
                                    <YearBox period={intl.formatMessage({ id: projects.find(item => item.projectId === projectId)?.periodId ?? "default" })} />
                                </div>
                                <h4>{intl.formatMessage({ id: 'Work' })}</h4>
                                <p>{intl.formatMessage({ id: projects.find(item => item.projectId === projectId)?.workId ?? "default" })}</p>
                                <h4>{intl.formatMessage({ id: 'Client' })}</h4>
                                <p>{intl.formatMessage({ id: projects.find(item => item.projectId === projectId)?.clientId ?? "default" })}</p>
                                <h4>{intl.formatMessage({ id: 'Architect' })}</h4>
                                <p>{intl.formatMessage({ id: projects.find(item => item.projectId === projectId)?.architectId ?? "default" })}</p>
                                {/* <div dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'certificates description' }) }} /> */}
                            </div>
                        </div>
                    </div>
                </div>

                <a
                    className={Styles.crossBox}
                    href={`/project/`}
                >
                    <CrossIcon className={Styles.cross} />
                </a>
                <div className={Styles.skew} />
                {
                    projectId && projects.length > projectId && <a
                        href={`/project-detail/?projectId=${projectId && projectId + 1}`}
                        className={Styles.next}
                    >
                        {intl.formatMessage({ id: 'NEXT PROJECT' })}
                        <MoreIcon />
                    </a>
                }
            </div>
        </>
    )
}

export default projectDetail
